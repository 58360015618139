body{
  height: 100vh;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.view-manager{
  height: 100%;
}
div.gauge-wrapper{
  height: 200px;
  background: transparent linear-gradient(180deg, #4E5582 0%, #0E0E37 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 14px #00000080;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  width: auto;
  padding: 0 50px;
}

div.gauge-button{
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: white;
}

div.gauge-button.button-minus{
  background-image: url(button-minus.svg);
  background-color: transparent;
}
div.gauge-button.button-plus{
  background-image: url(button-plus.svg);
  background-color: transparent;
}

div.slider-wrapper{
  width: 500px;
  margin: 0 50px;
  position: relative;
  height: 100px; /*60px;*/
}

div.slider-indicators{
  display: flex;
  height: 10px;
  width: calc(100% - 60px);
  left: 30px;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
div.slider-indicators-top{
  top: 0;
}
div.slider-indicators-bottom{
  bottom: 0;
}
div.slider-current-value{
  width: 60px;
  height: 60px;
  position: absolute;
  top: 75px;
  color: #999;
  left: 0px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}


div.slider-indicators .slider-indicator-dash{
  width: 0px;
  height: 100%;
  border-left: 2px solid gray;
}

div.slider-indicators .slider-indicator-dash:nth-child(1), 
div.slider-indicators .slider-indicator-dash:nth-child(5),
div.slider-indicators .slider-indicator-dash:nth-child(9) {
  border-color: lightgray;
}

div.range-custom{
  position: absolute;
  width: 100%;
  top: 20px;
  height: 60px;
}
input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  /* bboy style */
  z-index: 100;
  position: absolute;
  top: 19px;
  left: 0px;
}


.slider-track-mask1{
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 48px;
  background: transparent linear-gradient(180deg, #4E5582 0%, #0E0E37 100%) 0% 0% no-repeat padding-box;
  border-radius: 24px;
  z-index: 10;
}
.slider-track-mask2{
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  height: 48px;
  background: transparent linear-gradient(180deg, #393D59 0%, #161A2B 100%) 0% 0% no-repeat padding-box;
  border-radius: 24px;
  box-shadow: inset 0px 0px 4px #000000BF;
  z-index: 20;
}
.slider-track-mask3{
  position: absolute;
  top: 15px;
  left: 9px;
  width: calc(100% - 18px);
  height: 30px;
  background: #414776 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 4px 4px #0006;
  border-radius: 24px;
  z-index: 30;
}
.slider-track-mask4{
  position: absolute;
  top: 16px;
  left: 10px;
  width: calc(100% - 20px);
  height: 28px;
  background: transparent linear-gradient(180deg, #1B1D2D 0%, #1B1D2D 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 4px 4px #0006;
  border-radius: 24px;
  z-index: 40;
}


input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track,
/******** Firefox ********/
input[type="range"]::-moz-range-track { 
  background: transparent;
  border-radius: 24px;
  height: 30px;
  margin: 0 10px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -19px; /* Centers thumb on the track */
  background-image: url(slider-thumb.svg);
  background-color: transparent;
  width: 60px;
  height: 60px;
  
}

/***** Thumb Styles *****/
/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
  border: none;   /*Removes extra border that FF applies*/
  border-radius: 0;   /*Removes default border-radius that FF applies*/
  width: 60px;
  height: 60px;
  background-image: url(slider-thumb.svg);
  background-color: transparent;
}

#root, .App{
  width: 100%;
  height: 100%;
}
.page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.page .left{
  /* max-width: 920px; */
  background-color: lightblue;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: #32334D 0% 0% no-repeat padding-box;
}

.page .right{
  flex: 1 1 auto;
  background: #4F5170 0% 0% no-repeat padding-box;
  min-width: 320px;
}
.info-row{
  position: relative;
  padding: 0 30px;
  height: auto;
}
.left-row.juri-info{
  height: auto;
  background: #26283E 0% 0% no-repeat padding-box;
}
.left-row.voting-panel{
  flex: 1 0 auto
}

.right .info-row{
  display: flex;
}
.right .info-row > div{
  padding: 30px 0;
  text-align: left;
  flex: 1 0 auto;
}

.current-dancers{
/*   display: flex;
  align-items: flex-start;
  padding: 15px 0;
  justify-content: center;
  gap: 50px;
  max-width: 600px;
  margin: 0 auto; */
  display: inline-flex;
  align-items: flex-start;
  padding: 15px 0;
  justify-content: center;
  min-width: 600px;
}
.dancer{
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  align-items: center;
}
.dancer.active{
  opacity: 1;
}
.dancer-photo-ring{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  /* background-image: url(dancer-circle.svg);
  background-color: transparent; */
}
.dancer-photo-ring > div{
  position: absolute;
  border-radius: 50%;
}
.dancer-photo-ring-layer1{
  width: 80px;
  height: 80px;
  background: transparent linear-gradient(180deg, #393D59 0%, #161A2B 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #000000BF;
}
.dancer-photo-ring-layer2{
  width: 74px;
  height: 74px;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  /* UI Properties */
  background: transparent linear-gradient(180deg, #393D59 0%, #161A2B 100%) 0% 0% no-repeat padding-box;
  top: 3px;
  left:3px;
}
.dancer-photo-ring-layer3{
  width: 66px;
  height: 66px;
  background: transparent linear-gradient(180deg, #1359E5 0%, #5B1FBF 50%, #F2088D 100%) 0% 0% no-repeat padding-box;
  top: 7px;
  left: 7px;
}
.dancer-photo-ring-picture{
  border-radius: 50%;
  overflow: hidden;
  background-image: url(dancer-pic.png);
  background-color: transparent;
  top: 9px;
  left: 9px;
  width: 62px;
  height: 62px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 150%;
}
.dancer-name{
  padding: 5px 0;
  color: white;
  white-space: normal;
}


/* before slider */
.rating-dancer-info{
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: flex;
  justify-content: space-between;
  color: white;
  margin: 0 auto;
  min-width: 600px;
  padding: 0 30px;
  gap: 30px;
}
.rating-dancer-stats{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 15px 0;
}
.rating-dancer-stats .dancer-name{
  max-width: 320px;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.rating-dancer-stats .section-row{
  text-align: left;
}
.rating-dancer-stats .section-row.row1{
  flex-grow: 1;
}
.rating-dancer-stats .section-row.row2{
  display: flex;
  justify-content: space-between;
}
.rating-dancer-big-photo{
  padding: 15px 0px 0; /*0 15px 0 0;*/
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-end;
  
}
.rating-dancer-big-photo img{
  /* max-width: 100%; */
  height: 160px;
}
.section-label{
  color: #DEE1EB;
  text-transform: uppercase;
  font-size: 14px;
}
.rating-dancer-stats .section-row.row1 .dancer-name{
  font-weight: 600;
  font-size: 22px;
}
.edit-dancer-score-button{
  padding: 6px 20px;
  background-color: transparent;
  color: gray;
  border: 1px solid gray;
}
button.button1{
  position: relative;
  width: auto;
  height: 50px;
  text-align: center;
  padding: 5px 20px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 34px;
  padding: 6px 25px;
  color: white;
}
.button1 > div{
  position: absolute;
}
.button1-layer1{
  top:0;
  left:0;
  /* width: 112px;
  height: 50px; */
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #3BE8FF 0%, #2CB9FF 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.button1-layer2{
  top:1px;
  left:1px;
  /* width: 110px;
  height: 48px; */
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: transparent linear-gradient(180deg, #26283E 0%, #313549 100%) 0% 0% no-repeat padding-box;
  border-radius: 7px;
}
.button1-layer3{
  top:5px;
  left:5px;
  /* width: 102px;
  height: 40px; */
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: transparent linear-gradient(180deg, #0E0E37 0%, #4E5582 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.button1-layer4-label{
  top: 6px;
  left: 6px;
  /* width: 100px;
  height: 38px; */
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: transparent linear-gradient(180deg, #26283E 0%, #313549 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  /* label */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-columns{
  display: flex;
  flex-direction: column;
}

/* after slider */
.rating-dancer-score{
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  margin: 0 auto;
  min-width: 600px;
  padding: 30px;
  gap: 30px;


  -webkit-border-image: -webkit-linear-gradient(top, white, rgba(0, 0, 0, 0)) 1 100%;
  /* -moz-border-image: -moz-linear-gradient(top, white, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image: -o-linear-gradient(top, white, rgba(0, 0, 0, 0)) 1 100%; */
  border-image: linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 100%;
}

.rating-dancer-score .section-row.row1{
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
/* .rating-dancer-score .section-row.row1 .section-column{
  padding: 30px;
}
.rating-dancer-score .section-row.row2{
  padding: 30px;
} */

/* grid */
.flex-3-columns{
  display: flex;
}
.flex-3-columns > div:nth-child(1),
.flex-3-columns > div:nth-child(3){
  flex: 1 1 25%;
}
.flex-3-columns > div:nth-child(2){
  flex: 2 1 50%;
}

.event-info .flex-3-columns > div,
.juri-info .flex-3-columns > div{
  padding: 15px;
  text-align: left;
}
.event-info .flex-3-columns > div:nth-child(2),
.juri-info .flex-3-columns > div:nth-child(2){
  min-width: 600px;
}


#view2 .rating-dancers{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#view2 .rating-dancers .vs{
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: white;
  background: #32334D 0% 0% no-repeat padding-box;
  border: 1px solid #DEE1EB;
  border-radius: 16px;
}

#view2 .rating-dancer-info{
  max-width: none;
  border: none;
  margin: 0;
  justify-content: unset;
}

#view2 .rating-dancer-stats{
  justify-content: space-between;
}
#view2 .rating-dancer-stats .dancer-name{
  margin: 0;
}

#view2 .rating-dancers > div.dancer1 .rating-dancer-big-photo{
  background: transparent linear-gradient(180deg, #32334D 0%, #A92D20 100%) 0% 0% no-repeat padding-box;
}

#view2 .rating-dancers > div.dancer2 .rating-dancer-big-photo{
  background: transparent linear-gradient(180deg, #32334D 0%, #173A94 100%) 0% 0% no-repeat padding-box;
}
#view2 .rating-dancers > div.dancer2{
  flex-direction: row-reverse;
}
#view2 .rating-dancers > div.dancer2 .rating-dancer-stats{
  align-items: flex-end;
}
#view2 .rating-dancers > div.dancer2 .rating-dancer-stats .section-row{
  text-align: right;
}
#view2 .rating-dancer-big-photo{
  padding: 15px 30px 0;
  display: flex;

}
#view2 .rating-dancer-big-photo img{
  height: auto;
  max-height: 200px;
}

#view2 .slider-track-mask4{
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,15,255,1) 100%);
}

#view2 .rating-dancer-score{
  border: none;
  max-width: none;
}

#view2 .battle-score-table{
  display: flex;
  padding: 30px;
  gap: 60px;
}
#view2 .battle-score-table .table-column.jury-names{

}
#view2 .battle-score-table .table-column .header{
  padding: 15px;
}
#view2 .battle-score-table .table-column .body .row{
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 50px;
}
#view2 .battle-score-table .table-column .body .row .result-color{
  width: 100%;
  height: 25px;
  border: 1px solid white;
  border-radius: 5px;
}
#view2 .battle-score-table .table-column .body .row .result-color.red{
  background-color: red;
}

#view2 .battle-score-table .table-column .body .row .result-color.blue{
  background-color: blue;
}

.button-dancer-ring{
  cursor: pointer;
  background: none;
  box-shadow: none;
  border: none;
  flex: 1 1 25%;
}
